<script>
export default {
  name: 'LogoHyattZiva',
  props: {
    fill: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 659 127"
    :class="{ 'fill-current': fill }"
  >
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m307 118.4c-8.4 0-15.1-6.7-15.1-15 0-8.3 6.8-15 15.1-15 8.3 0 15 6.7 15 15 0 8.3-6.7 15-15 15zm0-25.1c-5.6 0-10.1 4.6-10.1 10.1 0 5.6 4.5 10.1 10.1 10.1 5.5 0 10-4.5 10-10.1 0-5.5-4.5-10.1-10-10.1z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m564.1 117.9h-5v-12.5l-11.6-16.4h5.7l8.4 11.9 8.4-11.9h5.8l-11.7 16.4v12.5"
    />
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m411.6 117.9h-14.3v-28.9h13.6c5 0 9.1 3.6 9.1 8 0 1.9-0.8 3.8-2.2 5.2l-0.3 0.4 0.4 0.3c2.1 1.6 3.4 4 3.4 6.5 0 4.7-4.4 8.5-9.7 8.5zm-9.3-12.8v8.5h9.3c2.7 0 4.8-1.9 4.8-4.2 0-2.4-2.1-4.3-4.8-4.3zm0-11.9v7.6h8.5c2.4 0 4.3-1.7 4.3-3.8 0-2.1-1.9-3.8-4.3-3.8z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m506.7 117.9h-2.3l-12.8-28.9h5.6l8.4 19.2 8.3-19.2h5.6l-12.8 28.9"
    />
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m441.4 118.4c-8.3 0-15-6.7-15-15 0-8.3 6.7-15 15-15 8.3 0 15 6.7 15 15 0 8.3-6.7 15-15 15zm0-25.1c-5.6 0-10.1 4.6-10.1 10.1 0 5.6 4.5 10.1 10.1 10.1 5.6 0 10.1-4.5 10.1-10.1 0-5.5-4.5-10.1-10.1-10.1z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m486.4 117.9h-2.5l-16.4-18.7v18.7h-5.1v-28.9h2.6l16.4 18.6v-18.6h5v28.9"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m546.7 117.9h-25.4v-4.3h25.4v4.3"
    />
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m534 108.6c-5.6 0-10.1-4.6-10.1-10.1 0-5.6 4.5-10.1 10.1-10.1 5.5 0 10.1 4.5 10.1 10.1 0 5.5-4.6 10.1-10.1 10.1zm0-15.9c-3.2 0-5.8 2.6-5.8 5.8 0 3.2 2.6 5.8 5.8 5.8 3.2 0 5.8-2.6 5.8-5.8 0-3.2-2.6-5.8-5.8-5.8z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m171.9 117.9h-5.1v-20.5l-8 14.8h-3.7l-8.1-14.8v20.5h-5v-28.9h5.8l9.1 16.4 9.2-16.4h5.8v28.9"
    />
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m207.7 117.9h-5.4l-2.6-5.7h-13.1l-2.7 5.7h-5.3l13.3-28.9h0.1 2.3l13.4 28.9zm-14.5-19.9l-4.6 9.9h9.2z"
    />
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m238.3 117.9h-5.8l-8.2-11.9h-5v11.9h-5v-28.9h14.3c5.3 0 9.6 3.8 9.6 8.5 0 4.3-3.6 7.8-8.2 8.4zm-18.9-16.1h9.2c2.7 0 4.9-1.9 4.9-4.3 0-2.4-2.2-4.3-4.9-4.3h-9.2z"
    />
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m270.6 117.9h-5.8l-8.2-11.9h-5v11.9h-5v-28.9h14.3c5.3 0 9.7 3.8 9.7 8.5 0 4.3-3.6 7.8-8.3 8.4zm-18.9-24.7v8.6h9.3c2.6 0 4.8-1.9 4.8-4.3 0-2.4-2.2-4.3-4.8-4.3z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m284.2 117.9h-5.1v-28.9h5.1v28.9"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m339.3 117.9h-5v-24.3h-8.7v-4.6h22.4v4.6h-8.7v24.3"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m366.7 117.9h-5.1v-24.3h-8.6v-4.6h22.3v4.6h-8.6v24.3"
    />
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m580.3 94.8l-1.2-0.2-0.9-0.7-0.6-0.9-0.2-1.1 0.2-1.2 0.6-0.9 0.9-0.6 1.2-0.3 1.1 0.3 1 0.6 0.6 0.9 0.2 1.2-0.2 1.1-0.6 0.9-1 0.7zm0-5.3l-0.9 0.1-0.7 0.5-0.5 0.8-0.2 0.9 0.2 1 0.5 0.7 0.7 0.5 0.9 0.2 0.9-0.2 0.8-0.5 0.5-0.7 0.1-1-0.1-0.9-0.5-0.8-0.8-0.5zm1.4 3.9h-0.7l-0.6-1.2h-0.5v1.2h-0.6v-3h1.2l0.4 0.1 0.4 0.2 0.2 0.3v0.3l-0.1 0.5-0.5 0.4zm-1.7-2.5v0.9h0.5l0.4-0.2 0.1-0.3-0.1-0.3-0.4-0.1z"
    />
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m86.1 118.2c-3.1 0-5-1.1-6.2-2.5v2.1h-4.5v-28.8h4.7v10.6c1.2-1.1 3.5-2.2 6.2-2.2 5.3 0 9.8 4.1 9.8 10.4 0 6.3-4.6 10.4-10 10.4zm-0.5-16.3c-3.2 0-5.7 2.3-5.7 5.9 0 3.6 2.5 5.9 5.7 5.9 3.2 0 5.7-2.3 5.7-5.9 0-3.6-2.5-5.9-5.7-5.9z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m107.3 126.3h-5.1l5.4-11-8.1-17.5h5.3l5.5 12.1 5.8-12.1h5.1l-13.9 28.5"
    />
    <path
      fill-rule="evenodd"
      :fill="fill ? '#808184' : 'currentColor'"
      d="m51.3 60.6h-5.7l-6.7-18.1h-26.5l-6.8 18.1h-5.6v-0.1l22.4-59h6.5zm-25.7-53.3l-11.5 30.4h22.9z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m101.5 60.6h-35.7v-59.1h5.6v54.3h30.1v4.8"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m152.5 60.6h-35.8v-59.1h5.6v54.3h30.2v4.8"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m184.2 41.7h-19.9v-5.2h19.9v5.2"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m208.7 60.6h-5.6v-59.1h5.6v59.1"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m275.1 60.6h-6l-33.3-50.7v50.7h-5.3v-59.1h6l33.3 50.8v-50.8h5.3v59.1"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m319.7 62.3q-11.9 0-18.8-8.4-6.9-8.3-6.9-22.9 0-14.5 6.9-22.7 7-8.3 19-8.3 10 0 16.2 5.1 6.3 5.2 6.7 13.7h-5.4q-0.6-6.5-5.3-10.2-4.7-3.8-12.2-3.8-9.6 0-14.9 6.9-5.4 6.8-5.4 19.2 0 12.5 5.5 19.5 5.5 7.1 15 7.1 7.8 0 12.6-4.3 4.8-4.3 5.3-11.9h5.3q-0.6 9.8-6.9 15.4-6.3 5.6-16.7 5.6z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m397.7 60.6h-35.7v-59.1h5.6v54.3h30.1v4.8"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m435 62.3q-11.2 0-16.8-5.7-5.7-5.8-5.7-17.1v-38h5.4v37.3q0 9.8 4.1 14.2 4 4.4 13 4.4 8.9 0 13-4.4 4.1-4.4 4.1-14.2v-37.3h5.4v38q0 11.3-5.7 17-5.7 5.8-16.8 5.8z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m497.5 62.3q-10.5 0-16.2-5-5.7-4.9-5.7-14.2v-0.4h5.4q0 7 4.4 10.9 4.4 3.8 12.2 3.8 6.9 0 10.9-3.1 3.9-3.1 3.9-8.6 0-5-2.7-7.7-2.8-2.7-11.1-4.8l-5.6-1.4q-9.1-2.3-12.6-5.9-3.4-3.6-3.4-10.1 0-7.2 5.3-11.5 5.1-4.3 13.7-4.3h0.6q9.1 0 14.2 4.7 5.3 4.8 5.4 13.3h-5.5q-0.1-6.4-3.9-9.8-3.8-3.4-10.8-3.4-6.5 0-10 2.9-3.6 2.9-3.6 8.3 0 2 0.6 3.6 0.5 1.6 1.7 2.8 1.2 1.2 3.1 2.1 1.9 0.9 4.6 1.6l6.9 1.7q10.2 2.6 14.4 6.5 4.1 3.9 4.1 10.9 0 7.9-5.4 12.5-5.4 4.6-14.9 4.6z"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m542.3 60.6h-5.5v-59.1h5.5v59.1"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m583.8 60.6h-5.8l-20.5-59.1h5.6l17.9 52.2 17.8-52.2h5.5l-20.5 59.1"
    />
    <path
      :fill="fill ? '#808184' : 'currentColor'"
      d="m658.7 60.6h-39.7v-59.1h38.7v4.9h-33.3v21.5h30.4v4.7h-30.4v23.2h34.3v4.8"
    />
  </svg>
</template>
